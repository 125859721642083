import { Box, Grid, Paper, Typography } from '@mui/material';
import React, { memo, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import CustomInput from '../../../../../../CustomInput';
import { numberToRealWithPeriod } from '../../../../../../../helpers/formatData';

const useStyles = makeStyles({
  inputAdornment: {
    marginBottom: 0,
  },
});

function DiscountsFromPrice({
  selectedProduct,
  priceValue,
  costValue,
  handleChangePhysicalStoreDiscount,
  blockFields,
}) {
  const classes = useStyles();

  const getPriceValue = () => {
    let price = priceValue;

    if (selectedProduct.additionalOnline > 0 && costValue > 0) {
      const percentage = 1 - (1 - selectedProduct.additionalOnline / costValue);
      price = priceValue * percentage + priceValue;
    }

    return price;
  };

  const [priceValueWithDiscount, setPriceValueWithDiscount] = useState(0);
  const [physicalStoreDiscount, setPhysicalStoreDiscount] = useState(0);
  const [physicalStoreDiscountPercentage, setPhysicalStoreDiscountPercentage] = useState(0);

  const changePhysicalStoreDiscount = (event) => {
    const { value } = event.target;

    handleChangePhysicalStoreDiscount(parseFloat(value) || 0);

    if (priceValue) {
      setPriceValueWithDiscount(getPriceValue() - value);

      const percentage = (1 - (1 - value / getPriceValue())) * 100;
      setPhysicalStoreDiscountPercentage(Math.round(percentage));
    } else {
      setPhysicalStoreDiscountPercentage(0);
    }

    setPhysicalStoreDiscount(value);
  };

  const changePhysicalStoreDiscountPercentage = (event) => {
    const { value } = event.target;

    if (priceValue) {
      const formatedValue = getPriceValue() * (value / 100);
      handleChangePhysicalStoreDiscount(formatedValue);

      setPriceValueWithDiscount(getPriceValue() - formatedValue);
      setPhysicalStoreDiscount(parseFloat(formatedValue.toFixed(2)));
    }

    setPhysicalStoreDiscountPercentage(value);
  };

  useEffect(() => {
    if (priceValue) {
      if (selectedProduct.physicalStoreDiscount) {
        const percentage =
          (1 - (1 - selectedProduct.physicalStoreDiscount / getPriceValue())) * 100;

        setPriceValueWithDiscount(getPriceValue() - selectedProduct.physicalStoreDiscount);
        setPhysicalStoreDiscount(parseFloat(selectedProduct.physicalStoreDiscount.toFixed(2)));
        setPhysicalStoreDiscountPercentage(Math.round(percentage));
      } else {
        setPriceValueWithDiscount(getPriceValue());
      }
    }
  }, [priceValue]);

  return (
    <Paper>
      <Paper>
        <Box padding={2}>
          <Box marginBottom={1}>
            <Typography variant="h3">Descontos no preço de venda</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Grid container alignItems="flex-end" spacing={0.5}>
                <Grid item xs={6}>
                  <CustomInput
                    label="Desconto loja física"
                    value={physicalStoreDiscount}
                    onChange={changePhysicalStoreDiscount}
                    size="medium"
                    variant="standard"
                    InputStartAdornment="R$"
                    InputAdornmentStyle={classes.inputAdornment}
                    disabled={blockFields}
                    number
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomInput
                    value={physicalStoreDiscountPercentage}
                    onChange={changePhysicalStoreDiscountPercentage}
                    size="medium"
                    variant="standard"
                    color="secondary"
                    InputEndAdornment="%"
                    InputAdornmentStyle={classes.inputAdornment}
                    disabled={blockFields}
                    number
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid container direction="column">
                <Grid item>
                  <Typography variant="caption">Venda loja física</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h1">{`R$ ${numberToRealWithPeriod(priceValueWithDiscount)}`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Paper>
  );
}
export default memo(DiscountsFromPrice);
