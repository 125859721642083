import { Grid } from '@mui/material';
import React, { memo, useCallback, useEffect, useState } from 'react';
import calculateProductCost from '../../../../../../helpers/calculateProductCost';
import SimpleDialog from '../../../../../Common/SimpleDialog';
import DialogSelectOneProduct from '../../../../../Products/Dialogs/DialogSelectOneProduct';
import ConversionTab from './ConversionTab';
import PriceTab from './PriceTab';
import SincronizedProductTab from './SincronizedProductTab';
import ValuesFromCost from './ValuesFromCost';
import ValuesFromPrice from './ValuesFromPrice';
import ImportDataTab from './ImportDataTab';
import ImportProductDataTab from './ImportProductDataTab';
import DiscountsFromPrice from './DiscountsFromPrice';

function DialogProductCost({
  openDialog,
  handleCloseDialog,
  entryOrderType,
  selectedProduct,
  setSelectedProduct,
  saveProductCost,
  blockFields,
}) {
  const [openDialogSelectProduct, setOpenDialogSelectProduct] = useState(false);
  const handleOpenDialogSelectProduct = useCallback(() => setOpenDialogSelectProduct(true), []);
  const handleCloseDialogSelectProduct = useCallback(() => setOpenDialogSelectProduct(false), []);

  const handleSelectSincronizedProduct = useCallback(
    (product) =>
      setSelectedProduct((oldFields) => ({
        ...oldFields,
        sincProductId: product.productId,
        sincName: product.name,
        sincLastPrice: product.price,
        sincLastCost: product.cost,
        unity: product.unity || 'UN',
      })),
    [setSelectedProduct],
  );

  const handleChangeUnityRate = useCallback(
    (unityRate) =>
      setSelectedProduct((oldFields) => ({
        ...oldFields,
        unityRate,
      })),
    [setSelectedProduct],
  );

  const handleChangeEntryUnityRate = useCallback(
    (entryUnityRate) =>
      setSelectedProduct((oldFields) => ({
        ...oldFields,
        entryUnityRate,
      })),
    [setSelectedProduct],
  );

  const handleChangeUnity = useCallback(
    (unity) =>
      setSelectedProduct((oldFields) => ({
        ...oldFields,
        unity: unity?.name,
        unityId: unity?.unityId,
      })),
    [setSelectedProduct],
  );

  const handleChangeEntryUnity = useCallback(
    (entryUnity) =>
      setSelectedProduct((oldFields) => ({
        ...oldFields,
        entryUnity: entryUnity?.name,
        entryUnityId: entryUnity?.unityId,
      })),
    [setSelectedProduct],
  );

  const handleChangeIpiRate = useCallback(
    (ipiRate) =>
      setSelectedProduct((oldFields) => ({
        ...oldFields,
        ipiRate,
      })),
    [setSelectedProduct],
  );

  const handleChangeStRate = useCallback(
    (stRate) =>
      setSelectedProduct((oldFields) => ({
        ...oldFields,
        stRate,
        hasSt: stRate > 0,
        simples: stRate > 0 ? 0.04 : 0.15,
      })),
    [setSelectedProduct],
  );

  const handleChangeShippingRate = useCallback(
    (shippingRate) =>
      setSelectedProduct((oldFields) => ({
        ...oldFields,
        shippingRate,
      })),
    [setSelectedProduct],
  );

  const handleChangeEntryUnitaryCost = useCallback(
    (entryUnitaryCost) =>
      setSelectedProduct((oldFields) => ({
        ...oldFields,
        entryUnitaryCost,
      })),
    [setSelectedProduct],
  );

  const handleChangeOthersCostRate = useCallback(
    (othersCostRate) =>
      setSelectedProduct((oldFields) => ({
        ...oldFields,
        othersCostRate,
      })),
    [setSelectedProduct],
  );

  const handleChangeSimples = useCallback(
    (simples) =>
      setSelectedProduct((oldFields) => ({
        ...oldFields,
        simples,
      })),
    [setSelectedProduct],
  );

  const handleChangeProfitMargin = useCallback(
    (profitMargin) =>
      setSelectedProduct((oldFields) => ({
        ...oldFields,
        profitMargin,
      })),
    [setSelectedProduct],
  );

  const handleChangeAdditionalOnline = useCallback(
    (additionalOnline) =>
      setSelectedProduct((oldFields) => ({
        ...oldFields,
        additionalOnline,
        hasAdditionalOnline: additionalOnline !== 0,
      })),
    [setSelectedProduct],
  );

  const handleChangeHasAdditionalOnline = useCallback(
    () =>
      setSelectedProduct((oldFields) => ({
        ...oldFields,
        hasAdditionalOnline: !oldFields.hasAdditionalOnline,
      })),
    [setSelectedProduct],
  );

  const handleChangeDifalValue = useCallback(
    (difalRate) =>
      setSelectedProduct((oldFields) => ({
        ...oldFields,
        difalRate,
      })),
    [setSelectedProduct],
  );

  const handleChangePhysicalStoreDiscount = useCallback(
    (physicalStoreDiscount) =>
      setSelectedProduct((oldFields) => ({
        ...oldFields,
        physicalStoreDiscount,
      })),
    [setSelectedProduct],
  );

  const [bouthCostValue, setBouthCostValue] = useState(0);
  const [priceValue, setPriceValue] = useState(0);
  const [costValue, setCostValue] = useState(0);
  const [ipiValue, setIpiValue] = useState(0);
  const [stValue, setStValue] = useState(0);
  const [othersCostValue, setOthersCostValue] = useState(0);
  const [shippingValue, setShippingValue] = useState(0);
  const [simplesValue, setSimplesValue] = useState(0);
  const [profitValue, setProfitValue] = useState(0);
  const [mlPriceValue, setMlPriceValue] = useState(0);
  const [finalQuantityValue, setFinalQuantityValue] = useState(0);
  const [difalValue, setDifalValue] = useState(0);
  const [salesCostValue, setSalesCostValue] = useState(0);

  const handleConfirmProductCost = useCallback(() => {
    let price = priceValue;

    if (selectedProduct.additionalOnline > 0 && costValue > 0) {
      const percentage = 1 - (1 - selectedProduct.additionalOnline / costValue);
      price = priceValue * percentage + priceValue;
    }

    saveProductCost({
      ...selectedProduct,
      unitaryCost: bouthCostValue,
      cost: costValue,
      ipi: ipiValue,
      st: stValue,
      othersCost: othersCostValue,
      price,
      quantity: finalQuantityValue,
      shippingCost: shippingValue,
      simplesCost: simplesValue,
      salesCost: salesCostValue,
    });

    return handleCloseDialog();
  }, [
    selectedProduct,
    costValue,
    ipiValue,
    stValue,
    othersCostValue,
    priceValue,
    finalQuantityValue,
    shippingValue,
    simplesValue,
    salesCostValue,
  ]);

  useEffect(() => {
    const productCost = calculateProductCost(selectedProduct);

    let mlPrice = productCost.price + 6;
    if (mlPrice < 20) {
      mlPrice += 5;
    }

    setDifalValue(productCost.difal);
    setFinalQuantityValue(productCost.quantity);
    setBouthCostValue(productCost.bouthCost);
    setMlPriceValue(mlPrice);
    setCostValue(productCost.cost);
    setIpiValue(productCost.ipi);
    setStValue(productCost.st);
    setOthersCostValue(productCost.othersCost);
    setShippingValue(productCost.shipping);
    setSimplesValue(productCost.simplesCost);
    setProfitValue(productCost.profit);
    setPriceValue(productCost.price);
    setSalesCostValue(productCost.salesCost);
  }, [selectedProduct]);

  const handleChangePriceConfirmed = useCallback(() => {
    setSelectedProduct((oldFields) => ({
      ...oldFields,
      priceConfirmed: !selectedProduct.priceConfirmed,
    }));
  }, [selectedProduct.priceConfirmed, setSelectedProduct]);

  return (
    <SimpleDialog
      openDialog={openDialog}
      handleClose={handleCloseDialog}
      dialogTitle={`Custeio - [${selectedProduct.supplierId}] ${selectedProduct.entryQuantity} ${
        selectedProduct.entryUnity || 'UN'
      } ${selectedProduct.name}`}
      maxWidth="lg"
      greyContent
      confirmButtonText="Salvar"
      cancelButtonText="Cancelar"
      handleCancelButton={handleCloseDialog}
      handleConfirmButton={handleConfirmProductCost}
      disableConfirmButton={
        selectedProduct.entryQuantity <= 0 || selectedProduct.entryUnitaryCost <= 0
      }
      content={
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Grid container spacing={1}>
              {entryOrderType === 'Importação para revenda' ? (
                <Grid item>
                  <ImportDataTab
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                  />
                </Grid>
              ) : null}
              <Grid item>
                <ConversionTab
                  selectedProduct={selectedProduct}
                  setSelectedProduct={setSelectedProduct}
                  handleChangeUnityRate={handleChangeUnityRate}
                  handleChangeUnity={handleChangeUnity}
                  handleChangeEntryUnityRate={handleChangeEntryUnityRate}
                  handleChangeEntryUnity={handleChangeEntryUnity}
                  handleChangeEntryUnitaryCost={handleChangeEntryUnitaryCost}
                  finalQuantityValue={finalQuantityValue}
                  costValue={costValue}
                  blockFields={blockFields}
                />
              </Grid>
              <Grid item>
                <ValuesFromCost
                  selectedProduct={selectedProduct}
                  handleChangeIpiRate={handleChangeIpiRate}
                  handleChangeStRate={handleChangeStRate}
                  handleChangeShippingRate={handleChangeShippingRate}
                  handleChangeOthersCostRate={handleChangeOthersCostRate}
                  handleChangeDifalValue={handleChangeDifalValue}
                  ipiValue={ipiValue}
                  stValue={stValue}
                  shippingValue={shippingValue}
                  othersCostValue={othersCostValue}
                  bouthCostValue={bouthCostValue}
                  difalValue={difalValue}
                />
              </Grid>
              <Grid item>
                <ValuesFromPrice
                  selectedProduct={selectedProduct}
                  handleChangeSimples={handleChangeSimples}
                  handleChangeProfitMargin={handleChangeProfitMargin}
                  handleChangeAdditionalOnline={handleChangeAdditionalOnline}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container direction="column" spacing={1}>
              {entryOrderType === 'Importação para revenda' ? (
                <Grid item>
                  <ImportProductDataTab
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                  />
                </Grid>
              ) : null}
              <Grid item>
                <SincronizedProductTab
                  handleOpenDialogSelectProduct={handleOpenDialogSelectProduct}
                  selectedProduct={selectedProduct}
                />
              </Grid>
              <Grid item>
                <DiscountsFromPrice
                  selectedProduct={selectedProduct}
                  priceValue={priceValue}
                  costValue={costValue}
                  handleChangePhysicalStoreDiscount={handleChangePhysicalStoreDiscount}
                />
              </Grid>
              <Grid item>
                <PriceTab
                  selectedProduct={selectedProduct}
                  priceValue={priceValue}
                  costValue={costValue}
                  difalValue={difalValue}
                  ipiValue={ipiValue}
                  stValue={stValue}
                  shippingValue={shippingValue}
                  othersCostValue={othersCostValue}
                  simplesValue={selectedProduct.simples}
                  profitValue={profitValue}
                  mlPriceValue={mlPriceValue}
                  bouthCostValue={bouthCostValue}
                  salesCostValue={salesCostValue}
                  handleChangePriceConfirmed={handleChangePriceConfirmed}
                  handleChangeHasAdditionalOnline={handleChangeHasAdditionalOnline}
                />
              </Grid>
            </Grid>
          </Grid>

          {openDialogSelectProduct ? (
            <DialogSelectOneProduct
              handleClose={handleCloseDialogSelectProduct}
              handleConfirmSelect={handleSelectSincronizedProduct}
              openDialog={openDialogSelectProduct}
              productName={
                selectedProduct.supplierId
                  ? `[${selectedProduct.supplierId}] ${selectedProduct.name}`
                  : selectedProduct.name
              }
              preSearchText={selectedProduct.supplierId || selectedProduct.name}
            />
          ) : null}
        </Grid>
      }
    />
  );
}

export default memo(DialogProductCost);
