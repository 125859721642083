import { Alert, Box, Grid, Snackbar } from '@mui/material';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import SquareWidget from '../SquareWidget';
import TopBar from '../TopBar';
import {
  capitalizeString,
  extractFirstAndLastName,
  formatHandleError,
} from '../../../helpers/formatData';
import SimpleBackdrop from '../../Common/SimpleBackdrop';
import { getUsers } from '../../../services/melhorGestao/users';

function SellersResume() {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [preferenceDate, setPreferenceDate] = useState(dayjs());
  const [filterOrderCompany, setFilterOrderCompany] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const response = await Axios.get('/orders/sellers', {
          params: {
            date: preferenceDate,
            filterOrderCompany,
            status: [
              'Pedido confirmado',
              'Pedido faturado',
              'Pedido embalado',
              'Pedido enviado',
              'Pedido entregue',
              'Pedido concluído',
            ],
          },
        });

        const users = await getUsers(response.data);
        setSellerList(users);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar vendedores',
          error,
        });
      }
    };
    fetchData();
  }, [preferenceDate, filterOrderCompany]);

  return (
    <Box position="relative" marginBottom={5}>
      <Box marginTop={2}>
        <TopBar
          filterOrderCompany={filterOrderCompany}
          setFilterOrderCompany={setFilterOrderCompany}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
          preferenceDate={preferenceDate}
          setPreferenceDate={setPreferenceDate}
          setSnackbar={setSnackbar}
        />
      </Box>
      <Box marginTop={2}>
        <Grid container spacing={2}>
          {!loading ? (
            sellerList.map((seller) => (
              <Grid item sm={3} style={{ width: '170%' }} key={seller._id}>
                <SquareWidget
                  title={capitalizeString(extractFirstAndLastName(seller.name))}
                  sellerId={seller._id}
                  filterOrderCompany={filterOrderCompany}
                  selectedUser={selectedUser}
                  selectedCity={selectedCity}
                  preferenceDate={preferenceDate}
                  setSnackbar={setSnackbar}
                />
              </Grid>
            ))
          ) : (
            <SimpleBackdrop loading={loading} absolutePosition />
          )}
        </Grid>
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default SellersResume;
