import { Alert, Box, Grid, Snackbar } from '@mui/material';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import SquareWidget from './SquareWidget';
import TopBar from './TopBar';
import SimpleBackdrop from '../Common/SimpleBackdrop';
import { formatHandleError } from '../../helpers/formatData';

function SalesResume() {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [preferenceDate, setPreferenceDate] = useState(dayjs());
  const [filterOrderCompany, setFilterOrderCompany] = useState([]);
  const [orderSourceList, setOrderSourceList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        let sourcers = [
          {
            source: '',
          },
        ];

        const response = await Axios.get('/orders/sourcers', {
          params: {
            filterOrderCompany,
            date: preferenceDate,
            userId: selectedUser?.userId,
            city: selectedCity?.city,
            state: selectedCity?.state,
          },
        });

        const excludedSources = [
          'Devolução fornecedor',
          'Remessa conserto',
          'Uso e consumo',
          'Transferência de estoque',
        ];

        sourcers = [
          ...sourcers,
          ...response.data
            .map((source) => ({ source }))
            .filter((data) => !excludedSources.includes(data.source)),
        ];

        setOrderSourceList(sourcers);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar origens dos pedidos',
          error,
        });
      }
    };
    fetchData();
  }, [preferenceDate, filterOrderCompany, selectedUser, selectedCity]);

  return (
    <Box position="relative" marginBottom={5}>
      <Box marginTop={2}>
        <TopBar
          filterOrderCompany={filterOrderCompany}
          setFilterOrderCompany={setFilterOrderCompany}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
          preferenceDate={preferenceDate}
          setPreferenceDate={setPreferenceDate}
          setSnackbar={setSnackbar}
        />
      </Box>
      <Box marginTop={2}>
        <Grid container spacing={2}>
          {!loading ? (
            orderSourceList.map(({ source }) => (
              <Grid item sm={3} key={source}>
                <SquareWidget
                  darkMode={!source || ''}
                  title={source || 'Total em vendas'}
                  source={source}
                  filterOrderCompany={filterOrderCompany}
                  selectedUser={selectedUser}
                  selectedCity={selectedCity}
                  preferenceDate={preferenceDate}
                  setSnackbar={setSnackbar}
                />
              </Grid>
            ))
          ) : (
            <SimpleBackdrop loading={loading} absolutePosition />
          )}
        </Grid>
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default SalesResume;
