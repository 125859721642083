const calculateProductCost = (product) => {
  const finalQuantity = (product.entryQuantity * product.entryUnityRate) / product.unityRate;
  const cost = (product.entryUnitaryCost / product.entryUnityRate) * product.unityRate;

  const ipi = (cost / 100) * product.ipiRate;
  const st = (cost / 100) * product.stRate;
  const othersCost = (cost / 100) * product.othersCostRate;
  const shipping = (cost / 100) * product.shippingRate;
  const difal = (cost / 100) * product.difalRate;

  const afrmm = parseFloat(product.importData?.afrmmValue) || 0;
  const bouthCost = cost + ipi + st + othersCost + shipping + difal + afrmm;
  const price = bouthCost / (1 - (product.profitMargin + product.simples));
  const profit = price * product.profitMargin;

  const salesCost = bouthCost + product.simples * price + product.additionalOnline;

  return {
    cost,
    ipi,
    st,
    othersCost,
    shipping,
    simples: product.simples,
    difal,
    bouthCost,
    unitaryCost: bouthCost,
    totalCost: product.entryQuantity * bouthCost,
    price,
    profit,
    quantity: finalQuantity,
    shippingCost: shipping,
    simplesCost: product.simples * price,
    salesCost,
  };
};

export default calculateProductCost;
